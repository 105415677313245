<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import type { HTMLAttributes } from "vue"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"
  import { IconVariants, iconVariants } from "@/components/ui/icons/index.ts"

  defineEmits<(e: "click", payload: MouseEvent) => void>()

  const props = defineProps<{
    size?: IconVariants["size"]
    variant?: IconVariants["variant"]
    class?: HTMLAttributes["class"]
    name: keyof typeof iconsMap
  }>()
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    :class="cn(iconVariants({ variant, size }), props.class)"
    :data-testid="`icon-${props.name}`"
    @click="(e) => $emit('click', e)"
  >
    <component :is="iconsMap[props.name]" />
  </svg>
</template>

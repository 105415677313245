import { ExternalRoute } from "@/constants/externalRoute.ts"
import { NavbarItem } from "@/types/Navbar.ts"

export const MAIN_NAVIGATION_ITEMS: NavbarItem[] = [
  { to: ExternalRoute.movingHome, label: "Moving Home" },
  { to: ExternalRoute.meterReadings, label: "Meter Readings" },
]

// Navbar on desktop as submenu, on tablet as sub-submenu and in mobile menu as submenu.
export const ABOUT_US_NAVIGATION_ITEM = {
  label: "About us",
  children: [
    {
      to: ExternalRoute.aboutUs,
      label: "About So Energy",
    },
    {
      to: ExternalRoute.reviews,
      label: "Customer reviews",
    },
    {
      to: ExternalRoute.news,
      label: "News & Views",
    },
    {
      to: ExternalRoute.careers,
      label: "Work for So",
    },
    {
      to: ExternalRoute.ourEnergy,
      label: "Our Energy",
    },
  ],
}

// Navbar on desktop as submenu, on tablet as sub-submenu and in mobile menu as submenu.
export const HELP_NAVIGATION_ITEM = {
  label: "Help",
  children: [
    { to: ExternalRoute.getSupport, label: "Get Support" },
    { to: ExternalRoute.energySaving, label: "Energy Saving Tips" },
    { to: ExternalRoute.unhappy, label: "Unhappy with Us?" },
    { to: ExternalRoute.emergency, label: "Emergency" },
    { to: ExternalRoute.priceCap, label: "Price Cap" },
    { to: ExternalRoute.troubleshooting, label: "IHD Troubleshooting" },
  ],
}

export const PRODUCTS_NAVIGATION_ITEM = {
  label: "Products",
  children: [
    {
      to: ExternalRoute.solar,
      label: "Solar",
    },
    {
      to: ExternalRoute.homeCover,
      label: "Home Cover",
    },
    {
      to: ExternalRoute.heatPumps,
      label: "Heat Pumps",
    },
    {
      to: ExternalRoute.smartMeters,
      label: "Smart Meters",
    },
  ],
}

// Desktop and mobile view only
export const NAVIGATION_ITEMS: NavbarItem[] = [
  ...MAIN_NAVIGATION_ITEMS,
  ABOUT_US_NAVIGATION_ITEM,
  HELP_NAVIGATION_ITEM,
  PRODUCTS_NAVIGATION_ITEM,
]

// Tablet view only
export const EXTENDED_NAVIGATION_ITEMS: NavbarItem[] = [
  {
    label: "Menu",
    children: [
      ...MAIN_NAVIGATION_ITEMS,
      ABOUT_US_NAVIGATION_ITEM,
      HELP_NAVIGATION_ITEM,
      PRODUCTS_NAVIGATION_ITEM,
    ],
  },
]

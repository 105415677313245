<script lang="ts" setup>
  import { cn } from "@/lib/utils.ts"
  import { ErrorMessage } from "vee-validate"
  import { toValue, type HTMLAttributes } from "vue"

  import { useFormField } from "./useFormField"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()

  const { name, formMessageId } = useFormField()
</script>

<template>
  <ErrorMessage
    :id="formMessageId"
    as="p"
    :name="toValue(name)"
    :class="
      cn('pl-5 text-xs font-normal text-red-400 dark:text-red-300', props.class)
    "
  />
</template>

<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import type { LabelProps } from "radix-vue"
  import type { HTMLAttributes } from "vue"
  import { Icon } from "@/components/ui/icons"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"

  import { useFormField } from "./useFormField"

  const props = defineProps<
    LabelProps & {
      class?: HTMLAttributes["class"]
      icon?: keyof typeof iconsMap
      id?: string
    }
  >()

  const { error, formMessageId } = useFormField()
</script>

<template>
  <div
    :id="formMessageId"
    :class="cn('flex w-full items-center justify-start gap-2', props.class)"
  >
    <Icon
      v-if="icon"
      :name="icon"
      size="sm"
      data-testid="form-supporting-label-icon"
      class="shrink-0 text-secondary-500 dark:text-secondary-300"
    />
    <div
      :class="
        cn(
          error && 'text-destructive',
          'text-xs font-normal leading-5 text-secondary-500 dark:text-secondary-300',
          { 'pl-5': !icon },
        )
      "
    >
      <slot />
    </div>
  </div>
</template>

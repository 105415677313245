<script setup lang="ts">
  import { AuthenticateMutationError } from "@/api/authentication/authentication"
  import { ErrorCode } from "@/api/model/errorCode"
  import { useLogin } from "@/authentication/authenticationService"
  import { getRedirectUrl } from "@/lib/getRedirectUrl"
  import { getMyAccountUrl } from "@/lib/websiteUrls"
  import { isServerError } from "@/plugins/datadog/datadogHelper"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { useRoute } from "vue-router"
  import * as z from "zod"
  import Alert from "@/components/ui/alert/Alert.vue"
  import { AlertDescription } from "@/components/ui/alert/index.ts"
  import { Button } from "@/components/ui/button"
  import { Checkbox } from "@/components/ui/inputs/checkbox"
  import PasswordField from "@/components/ui/inputs/password-field/PasswordField.vue"
  import { TextInput } from "@/components/ui/inputs/text-input"

  const legacyMyAccountUrl = getMyAccountUrl()
  const login = useLogin()
  const currentRoute = useRoute()
  const formSchema = toTypedSchema(
    z.object({
      email: z
        .string({
          required_error: "Please enter a valid email",
          invalid_type_error: "Please enter a valid email",
        })
        .min(1, "Please enter a valid email")
        .email("Please enter a valid email"),
      password: z
        .string({
          required_error: "Please enter a valid password",
          invalid_type_error: "Please enter a valid password",
        })
        .min(1, "Please enter a valid password"),
      extendedSession: z.boolean().default(false).optional(),
    }),
  )

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const loginErrorCode = computed(() => {
    return login.error.value?.response?.data.errorCode
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login.mutateAsync({
        data: {
          email: data.email,
          password: data.password,
          extendedSession: !!data.extendedSession,
        },
      })
      const redirectUrl =
        getRedirectUrl(currentRoute.query.redirect) || legacyMyAccountUrl
      window.location.assign(redirectUrl)
    } catch (error) {
      if (isServerError(error)) {
        const typedError = error as AuthenticateMutationError
        const errorCode = typedError.response?.data?.errorCode
        const expectedErrorCodes: ErrorCode[] = [
          ErrorCode.invalidCredentials,
          ErrorCode.accountLocked,
        ]
        const isExpectedError =
          error.message === "Network Error" ||
          (errorCode && expectedErrorCodes.includes(errorCode))
        if (!isExpectedError) throw error
      }
    }
  })
</script>

<template>
  <section
    class="my-4 flex flex-col items-center justify-center gap-10 sm:my-11"
  >
    <h1 class="max-w-80 text-center text-4xl">
      Welcome to your So Energy account.
    </h1>
    <p>Please sign in to your account</p>
    <form
      class="w-full sm:w-1/2 lg:w-1/3"
      data-testid="login-form"
      novalidate
      @submit="onSubmit"
    >
      <div class="space-y-10 text-left">
        <TextInput
          label="Email address"
          name="email"
          autofocus
          type="email"
          autocomplete="email"
          data-testid="login-email-input"
        />
        <PasswordField
          label="Password"
          name="password"
          type="password"
          autocomplete="current-password"
          data-testid="login-password-input"
        />
      </div>
      <Checkbox label="Remember me" name="extendedSession" class="mb-10 mt-3" />
      <p>
        <a
          :href="`${legacyMyAccountUrl}password-reset`"
          class="link text-color-primary-600 mb-10 block text-center"
          data-testid="login-forgot-password-link"
        >
          Forgot your password?</a
        >
      </p>

      <Alert v-if="!!login.error.value" variant="error" class="mb-10 text-left">
        <AlertDescription>
          <template v-if="login.error.value?.message === 'Network Error'">
            Cannot reach the So Energy server
          </template>
          <template v-else-if="loginErrorCode === ErrorCode.invalidCredentials">
            Email or password is incorrect. Please try again.
          </template>
          <template v-else-if="loginErrorCode === ErrorCode.accountLocked">
            Your account has been locked for 5 minutes due to multiple failed
            login attempts. Please wait before trying again. If you've forgotten
            your password, you can
            <a class="link" :href="`${legacyMyAccountUrl}password-reset`"
              >reset it now</a
            >.
          </template>
          <template v-else> Unknown error! </template>
        </AlertDescription>
      </Alert>
      <div class="flex items-center justify-center space-x-2">
        <Button
          type="submit"
          class="m-auto"
          data-testid="login-btn"
          :loading="login.isPending.value"
          >Log in</Button
        >
      </div>
    </form>
    <div class="max-w-[37.5rem] space-y-3 text-center">
      <p class="font-medium">New to So Energy?</p>
      <p>
        If you have a switch in progress and have not yet accessed your account,
        <a
          data-testid="login-activation-link"
          :href="`${legacyMyAccountUrl}activate-account`"
          class="text-primary-600 hover:underline"
        >
          activate your account here
        </a>
      </p>
    </div>
  </section>
</template>

import { isAuthenticated } from "@/authentication/authenticationService"
import { getMyAccountUrl } from "@/lib/websiteUrls"
import { RouteLocationNormalizedGeneric } from "vue-router"

export const beforeEachGuard = async function (
  to: RouteLocationNormalizedGeneric,
) {
  const requiresAnon = to.matched.some((record) => record.meta.requiresAnon)
  if (requiresAnon && isAuthenticated()) {
    const legacyMyAccountUrl = getMyAccountUrl()
    const queryString = window.location.search
    window.location.assign(`${legacyMyAccountUrl}${queryString}`)
    return false
  }
}

import ArrowLeft from "@/components/ui/icons/ArrowLeft.vue"
import ArrowRight from "@/components/ui/icons/ArrowRight.vue"
import Check from "@/components/ui/icons/Check.vue"
import ChevronDown from "@/components/ui/icons/ChevronDown.vue"
import ChevronRight from "@/components/ui/icons/ChevronRight.vue"
import CircleCheck from "@/components/ui/icons/CircleCheck.vue"
import CircleInfo from "@/components/ui/icons/CircleInfo.vue"
import ClosedEye from "@/components/ui/icons/ClosedEye.vue"
import Eye from "@/components/ui/icons/Eye.vue"
import HexagonInfo from "@/components/ui/icons/HexagonInfo.vue"
import Plus from "@/components/ui/icons/Plus.vue"
import Spinner from "@/components/ui/icons/Spinner.vue"
import TriangleInfo from "@/components/ui/icons/TriangleInfo.vue"
import X from "@/components/ui/icons/X.vue"

// REMEMBER to SET STROKE='currentColor' on every child element of the svg, so that the stroke inherits text color

export const iconsMap = {
  ArrowRight: ArrowRight,
  ArrowLeft: ArrowLeft,
  ChevronRight: ChevronRight,
  ChevronDown: ChevronDown,
  Check: Check,
  CircleCheck: CircleCheck,
  CircleInfo: CircleInfo,
  ClosedEye: ClosedEye,
  Eye: Eye,
  HexagonInfo: HexagonInfo,
  TriangleInfo: TriangleInfo,
  Plus: Plus,
  Spinner: Spinner,
  x: X,
} as const

export type IconType = keyof typeof iconsMap

<script setup lang="ts">
  const props = defineProps<{
    to: string
    label: string
  }>()
</script>

<template>
  <li
    class="flex border-b-2 border-secondary-300 px-2 py-4 text-secondary-700 dark:border-secondary-500 dark:text-secondary-100"
    :data-testid="`${props.label}-mobile-nav-item`"
  >
    <a :href="to" class="w-full text-lg font-medium">
      {{ props.label }}
    </a>
  </li>
</template>

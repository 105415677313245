<script setup lang="ts">
  import { useBreakpoint } from "@/lib/useBreakpoint.ts"
  import { useColorMode } from "@vueuse/core"
  import { inject, onMounted } from "vue"
  import Footer from "@/components/ui/footer/Footer.vue"
  import NavbarContainer from "@/components/ui/navbar/NavbarContainer.vue"

  const mode = useColorMode()
  const isStaging = inject<boolean>("isStaging")
  const isTablet = useBreakpoint("sm")

  onMounted(() => (mode.value = "light"))
</script>

<template>
  <div class="w-100 flex flex-col">
    <div class="sticky top-0 z-50">
      <p
        v-if="isStaging"
        data-testid="staging-banner"
        class="top-0 w-full bg-yellow-300 p-2 text-center text-black"
      >
        <span class="font-medium">Staging preview:</span> Some changes may be
        unfinished.
        <br v-if="!isTablet" />
        Please use the
        <a class="link text-black" href="https://fe-nexus.sandbox.soenergy.co/"
          >sandbox environment</a
        >.
      </p>
      <NavbarContainer />
    </div>
    <main class="container flex flex-col py-6 sm:flex-row sm:flex-nowrap">
      <RouterView name="sidebar" />
      <div class="flex-1">
        <RouterView />
      </div>
    </main>
    <Footer />
  </div>
</template>

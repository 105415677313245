<script setup lang="ts">
  import { useVModel } from "@vueuse/core"
  import { ref } from "vue"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"
  import { TextInput } from "@/components/ui/inputs/text-input"

  const isPasswordShown = ref(false)

  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps<{
    defaultValue?: string | number
    modelValue?: string | number
    label: string
    extraLabel?: string
    supportingLabel?: string
    supportingLabelIcon?: keyof typeof iconsMap
    name: string
    autofocus?: boolean
    type?: string
    autocomplete?: string
    disabled?: boolean
    placeholder?: string
  }>()

  const emits =
    defineEmits<(e: "update:modelValue", payload: string | number) => void>()
  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })
</script>

<template>
  <TextInput
    :default-value="defaultValue"
    :model-value="modelValue"
    :label="label"
    :extra-label="extraLabel"
    :supporting-label="supportingLabel"
    :supporting-label-icon="supportingLabelIcon"
    :name="name"
    :autofocus="autofocus"
    :disabled="disabled"
    :placeholder="placeholder"
    :icon="isPasswordShown ? 'Eye' : 'ClosedEye'"
    :icon-aria-label="isPasswordShown ? 'Hide password' : 'Show password'"
    :icon-aria-expanded="isPasswordShown"
    icon-position="trailing"
    :type="isPasswordShown ? 'input' : 'password'"
    :autocomplete="autocomplete"
    @icon-clicked="isPasswordShown = !isPasswordShown"
  />
</template>

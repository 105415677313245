<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"

  import { alertVariants, type AlertVariants } from "."

  const props = defineProps<{
    class?: HTMLAttributes["class"]
    variant: AlertVariants["variant"]
  }>()

  const iconVariant: Record<string, keyof typeof iconsMap> = {
    success: "CircleCheck",
    info: "CircleInfo",
    warning: "HexagonInfo",
    error: "TriangleInfo",
  }
</script>

<template>
  <div :class="cn(alertVariants({ variant }), props.class)" role="alert">
    <div class="flex gap-3">
      <Icon
        :name="iconVariant[variant!]"
        size="lg"
        :data-testid="`alert-icon-${variant}`"
      />
      <div class="flex-1" data-testid="alert-content">
        <slot />
      </div>
    </div>
    <slot name="action" />
  </div>
</template>

<template>
  <g clip-path="url(#clip0_2196_120)">
    <path
      d="M6 12L10 16L18 8"
      stroke-width="2"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke-width="2"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_2196_120">
      <rect width="24" height="24" stroke="currentColor" />
    </clipPath>
  </defs>
</template>

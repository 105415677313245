<script setup lang="ts">
  import { NavbarItem } from "@/types/Navbar.ts"
  import { NavigationMenuContent, NavigationMenuTrigger } from "radix-vue"
  import { ref, watchEffect } from "vue"
  import { Button } from "@/components/ui/button"
  import NestedSubitem from "@/components/ui/navbar/items/NestedSubitem.vue"

  const props = defineProps<{
    triggerLabel: string
    subItems: NavbarItem[]
    itemClicked: () => void
    parentOpened: boolean
  }>()

  const openNestedItems = ref(true)

  watchEffect(() => {
    openNestedItems.value = props.parentOpened
  })
</script>

<template>
  <NavigationMenuTrigger as-child>
    <Button variant="text" :data-testid="`${triggerLabel}-trigger`">
      {{ triggerLabel }}
    </Button>
  </NavigationMenuTrigger>
  <NavigationMenuContent
    class="top-2 rounded-md border-[1px] border-secondary-300 bg-secondary-100 shadow-md dark:border-secondary-500 dark:bg-secondary-500"
    :data-testid="`${triggerLabel}-submenu-content`"
  >
    <div class="flex min-w-[325px] flex-col space-y-2 p-4">
      <template v-for="({ to, label, children }, index) in props.subItems">
        <Button
          v-if="to"
          :key="index"
          variant="navSubItem"
          size="nav"
          as-child
          class="group w-full justify-start rounded-full pl-4"
          :data-testid="`${label}-submenu-nav-item`"
          @click="props.itemClicked"
        >
          <a :href="to">{{ label }}</a>
        </Button>

        <NestedSubitem
          v-if="children?.length && openNestedItems"
          :key="index"
          :sub-items="children"
          :trigger-label="label"
          :item-clicked="props.itemClicked"
        />
      </template>
    </div>
  </NavigationMenuContent>
</template>

import * as authenticationApi from "@/api/authentication/authentication"
import { AuthenticationRequest, AuthenticationResponse } from "@/api/model"
import { useCookies } from "@vueuse/integrations/useCookies"

import { getApiRootDomain } from "./utils"

const AuthCookie = {
  longLivedSession: "myaccount_remember-me_long_lived_refresh_token",
  refreshTokenAvailable: "website_refresh_token_available",
}

/** Sets legacy cookies required by old MyAccount to recognise that user is authenticated and length of their session */
function setCookiesSupportingLegacyAuthentication(
  sessionExpiryDateString: string,
  isSessionExtended: boolean,
) {
  const cookies = useCookies()
  const sessionExpiryDate = new Date(sessionExpiryDateString)
  if (isSessionExtended) {
    cookies.set(AuthCookie.longLivedSession, true, {
      domain: getApiRootDomain(),
      expires: sessionExpiryDate,
      path: "/",
    })
  }
  cookies.set(AuthCookie.refreshTokenAvailable, true, {
    domain: getApiRootDomain(),
    expires: sessionExpiryDate,
    path: "/",
  })
}

export function isAuthenticated() {
  return !!useCookies().get(AuthCookie.refreshTokenAvailable)
}

export function useLogin() {
  return authenticationApi.useAuthenticate({
    request: {
      withCredentials: true,
    },
    mutation: {
      onSuccess: (
        response: AuthenticationResponse,
        request: { data: AuthenticationRequest },
      ) => {
        setCookiesSupportingLegacyAuthentication(
          response.sessionExpiry,
          request.data.extendedSession,
        )
      },
    },
  })
}

import { cva, type VariantProps } from "class-variance-authority"

export { default as AlertTitle } from "./AlertTitle.vue"
export { default as AlertDescription } from "./AlertDescription.vue"

export const alertVariants = cva(
  "relative w-full rounded-2xl border p-4 flex leading-6 items-center justify-between text-sm",
  {
    variants: {
      variant: {
        success:
          "bg-green-100 text-green-500 border-green-200 dark:bg-green-600 dark:text-green-100 [&_svg]:stroke-green-500 dark:[&_svg]:stroke-green-300",
        info: "bg-blue-100 text-blue-500 border-blue-200 dark:bg-blue-600 dark:text-blue-100 [&_svg]:stroke-blue-500 dark:[&_svg]:stroke-blue-300",
        warning:
          "bg-yellow-100 text-yellow-500 border-yellow-200 dark:bg-yellow-600 dark:text-yellow-100 [&_svg]:stroke-yellow-500 dark:[&_svg]:stroke-yellow-300",
        error:
          "bg-red-100 text-red-500 border-red-200 dark:bg-red-600 dark:text-red-100 [&_svg]:stroke-red-500 dark:[&_svg]:stroke-red-300",
      },
    },
    defaultVariants: {
      variant: "success",
    },
  },
)

export type AlertVariants = VariantProps<typeof alertVariants>

<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import { NavbarItem } from "@/types/Navbar.ts"
  import { ref } from "vue"

  const props = defineProps<{
    triggerLabel: string
    subItems: NavbarItem[]
    toggleNavbar?: () => void
  }>()

  const open = ref(false)
</script>

<template>
  <li
    class="flex flex-col border-b-2 border-secondary-300 px-2 py-4 text-secondary-700 dark:border-secondary-500 dark:text-secondary-100"
  >
    <span
      role="button"
      tabindex="0"
      class="w-full text-lg font-medium"
      :data-testid="`${triggerLabel}-nested-mobile-submenu-trigger`"
      @click="open = !open"
    >
      {{ props.triggerLabel }}
    </span>
    <ul
      :class="cn(`mt-3 hidden flex-col gap-3 text-left`, open && 'flex')"
      @click="props.toggleNavbar"
    >
      <li
        v-for="(subItem, index) in props.subItems"
        :key="index"
        class="flex justify-start py-1 pl-6"
        :data-testid="`${subItem.label}-mobile-nested-submenu-nav-item`"
      >
        <a :href="subItem.to" class="w-full text-sm font-normal">
          {{ subItem.label }}
        </a>
      </li>
    </ul>
  </li>
</template>

<template>
  <g clip-path="url(#clip0_5362_6575)">
    <path
      d="M2 8C9 15 15 15 22 8M20 10.5L21.5 12M12 13.25V15.5"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M2.5 12L4 10.5M6.5 14.5L7.5 12.5M17.5 14.5L16.5 12.5"
      stroke="currentColor"
      stroke-linecap="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_5362_6575">
      <rect
        width="22"
        height="9.55518"
        fill="white"
        transform="translate(1 7)"
      />
    </clipPath>
  </defs>
</template>

export const Route = {
  home: `/`,
  profile: `/profile`,
  login: `/login`,
  maintenance: `/maintenance`,
  passwordReset: `/password-reset`,
  passwordChange: `/password-change`,
  activateAccount: `/activate-account`,
  readings: "/readings",
  help: "/help",
  payments: "/payments",
  directDebit: "/direct-debit",
  notFound: `/:pathMatch(.*)*`,
} as const

export type Route = (typeof Route)[keyof typeof Route]

<template>
  <path
    d="M16.6224 1H7.51041L1.06641 7.444V16.556L7.51041 23H16.6224L23.0664 16.556V7.444L16.6224 1Z"
    stroke="currentColor"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="square"
  />
  <path
    d="M12.0664 7V13"
    stroke="currentColor"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="square"
  />
  <path
    d="M12.0664 18C12.6187 18 13.0664 17.5523 13.0664 17C13.0664 16.4477 12.6187 16 12.0664 16C11.5141 16 11.0664 16.4477 11.0664 17C11.0664 17.5523 11.5141 18 12.0664 18Z"
    stroke="currentColor"
  />
</template>
